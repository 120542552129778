










































































































































































































































































































































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MGenerateDiscountCode: () =>
      import("@/components/molecules/m-generate-discount-code.vue"),
  },
  setup(_, { root }) {
    const model = reactive<{
      data: any;
    }>({
      data: {
        name: "",
        englishName: "",
        registrationFeeGroup: "",
        status: true,
        counter: "",
        isCompanionRequired: false,
        isRegistrationCodeRequired: false,
        registrationFeeCodesData: {
          type: "",
          codes: [],
        },
        registrationCode: null,
        additionalField: false,
        participantGroup: "",
        additionalServicesIds: [],
        additionalInformation: "",
        englishAdditionalInformation: "",
        registrationFeeTerms: [
          {
            price: 0,
            startDate: null,
            endDate: null,
            paymentDate: null,
            message: "Po tym terminie rejestracja zostanie anulowana.",
          },
        ],
        roomId: "",
        accommodationFrom: "",
        accommodationTo: "",
        accommodationResignation: false,
      },
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      table: false,
      loaded: false,
      item: [
        {
          startDateClose: false,
          endDateClose: false,
          paymentDateClose: false,
        },
      ],
      counter: false,
      registrationFeeGroup: [
        {
          price: 0,
          startDate: null,
          endDate: null,
          paymentDate: null,
          message: "Po tym terminie rejestracja zostanie anulowana.",
        },
      ],
      participantGroup: [],
      discounts: [],
      services: [],
      hotels: [],
      minDate: new Date().toISOString().split("T")[0],
      fromDate: "",
      toDate: "",
      isCompanionEnabled: false,
      singleCodeValue: "",
      singleCode: "",
    });

    //  Start fetch single fee data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`registration-fee/${root.$route.params.rfid}`)
        .then(({ data: { registrationFee } }) => {
          model.data.order = registrationFee.order;
          model.data.name = registrationFee.name;
          model.data.englishName = registrationFee.englishName;
          model.data.status = registrationFee.state;
          model.data.discountsIds = registrationFee.discounts?.map(
            (d: any) => d.id
          );
          model.data.registrationFeeGroup =
            registrationFee.registrationFeeGroup.id;
          model.data.counter = registrationFee.amount;
          model.data.isCompanionRequired = registrationFee.isCompanionRequired;
          model.data.isRegistrationCodeRequired =
            registrationFee.isRegistrationCodeRequired;
          model.data.registrationFeeCodesData.codes = registrationFee
            .registrationCodes.length
            ? registrationFee.registrationCodes.map((el: any) => el.value)
            : [];
          model.data.registrationFeeCodesData.type =
            registrationFee.isRegistrationCodeRequired &&
            registrationFee.registrationCodes.length
              ? registrationFee.registrationCodes[0].type
              : null;
          model.data.registrationCode = registrationFee.registrationCode;
          model.data.additionalField = registrationFee.additionalField;
          model.data.participantGroup = registrationFee.participantGroup?.id;
          model.data.additionalServicesIds = registrationFee.additionalServices?.map(
            (el: any) => el.id
          );
          model.data.roomId = registrationFee.room?.id;
          model.data.accommodationFrom = registrationFee.accommodationFrom?.split(
            "T"
          )[0];
          model.data.accommodationTo = registrationFee.accommodationTo?.split(
            "T"
          )[0];
          model.data.accommodationResignation =
            registrationFee.accommodationResignation;
          model.data.additionalInformation =
            registrationFee.additionalInformation;
          model.data.englishAdditionalInformation =
            registrationFee.englishAdditionalInformation;
          model.data.registrationFeeTerms =
            registrationFee.registrationFeeTerms;

          registrationFee.registrationFeeTerms.forEach(() => {
            state.item.push({
              startDateClose: false,
              endDateClose: false,
              paymentDateClose: false,
            });
          });

          state.counter = model.data.counter
            ? true
            : model.data.counter === 0
            ? true
            : false;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single fee data

    const fetchDiscounts = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/discount`)
        .then(({ data: { discounts } }) => {
          state.discounts = discounts
            .filter((el: any) => !el.isGlobal)
            .map((d: any) => ({
              id: d.id,
              name: d.name,
            }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.discounts = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchDiscounts);

    const fetchHotels = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/hotel`)
        .then(({ data: { hotels } }) => {
          state.hotels = hotels
            .map((el: any) =>
              el.rooms.map((item: any) => ({
                hotelIsEnabled: el.isEnabled,
                hotelName: el.location.name,
                roomIsEnabled: item.isEnabled,
                id: item.id,
                name: item.name,
              }))
            )
            .flat()
            .filter((el: any) => el.hotelIsEnabled && el.roomIsEnabled);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.hotels = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchHotels);

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.fromDate = event.accommodationModule.from?.split("T")[0];
          state.toDate = event.accommodationModule.to?.split("T")[0];
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(fetchEvent);

    const fetchModuleConfig = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}/registration-module`)
        .then(({ data }) => {
          state.isCompanionEnabled = data.isCompanionEnabled;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(fetchModuleConfig);

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        order: model.data.order,
        name: model.data.name,
        englishName: model.data.englishName,
        registrationFeeGroup: model.data.registrationFeeGroup,
        state: model.data.status,
        discountsIds:
          model.data.discountsIds.map((d: any) => ({ id: d })) || undefined,
        roomId: model.data.roomId || undefined,
        accommodationFrom: model.data.accommodationFrom || undefined,
        accommodationTo: model.data.accommodationTo || undefined,
        accommodationResignation: model.data.accommodationResignation || false,
        amount: model.data.counter,
        additionalField: model.data.additionalField || false,
        participantGroup: model.data.participantGroup || undefined,
        additionalServicesIds: model.data.additionalServicesIds || undefined,
        additionalInformation: model.data.additionalInformation || undefined,
        englishAdditionalInformation:
          model.data.englishAdditionalInformation || undefined,
        registrationFeeTerms: model.data.registrationFeeTerms,

        isCompanionRequired: model.data.isCompanionRequired || false,
        isRegistrationCodeRequired:
          model.data.isRegistrationCodeRequired || false,
        registrationFeeCodesData: model.data.registrationFeeCodesData || null,
      };

      state.loading = true;

      axiosInstance
        .put(
          `event/${root.$route.params.id}/registration-fee/${root.$route.params.rfid}`,
          data,
          {
            headers: {
              event: root.$route.params.id,
            },
          }
        )
        .then(() => {
          state.success = true;
          model.data = {};
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    // Fetch registrtion Fee groups

    const fetchRegistrationFeeGroup = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/registration-fee/group`)
        .then(({ data }) => {
          state.registrationFeeGroup = data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.registrationFeeGroup = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchRegistrationFeeGroup);

    // End Fetch registrtion Fee groups

    // Fetch participant groups

    const fetchParticipantGroup = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant/group/select`)
        .then(({ data: { groups } }) => {
          state.participantGroup = groups;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.participantGroup = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchParticipantGroup);

    // End Fetch participant groups

    const fetchServices = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/additional-service-group`)
        .then(({ data: { additionalServiceGroups } }) => {
          state.services = additionalServiceGroups
            .map((item: any) =>
              item.additionalServices.map((el: any) => ({
                groupName: item.name,
                groupIsEnabled: item.isEnabled,
                id: el.id,
                name: el.name,
                isEnabled: el.isEnabled,
              }))
            )
            .flat();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.services = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchServices);

    const addItem = () => {
      model.data.registrationFeeTerms.push({
        price: 0,
        startDate: null,
        endDate: null,
        paymentDate: null,
        message: "Po tym terminie rejestracja zostanie anulowana.",
      });
      state.item.push({
        startDateClose: false,
        endDateClose: false,
        paymentDateClose: false,
      });
    };

    const removeItem = (index: number) => {
      model.data.registrationFeeTerms.splice(index, 1);
    };

    watch(
      () => state.counter,
      () => {
        if (state.counter == false) {
          model.data.counter = null;
        }
      }
    );
    watch(
      () => state.singleCodeValue,
      () => {
        if (state.singleCodeValue.length > 1)
          model.data.registrationFeeCodesData.codes = [state.singleCodeValue];
      }
    );

    const getCodes = (value: string[]) => {
      model.data.registrationFeeCodesData.codes = value.map((e) => e);
    };

    const removeCode = (index: number) => {
      model.data.registrationFeeCodesData.codes.splice(index, 1);
    };

    const copySingleCode = (index: number) => {
      navigator.clipboard.writeText(
        model.data.registrationFeeCodesData.codes[index]
      );
      root.$store.commit("snackbar/PUSH_MESSAGE", {
        id: uuid(),
        color: "primary",
        message: root.$tc("layout.misc.codeCopied"),
      });
    };

    const addSingleCode = () => {
      model.data.registrationFeeCodesData.codes.push(state.singleCode);
      state.singleCode = "";
    };

    const selectAll = () => {
      if (
        model.data.additionalServicesIds &&
        model.data.additionalServicesIds.length
      ) {
        model.data.additionalServicesIds = [];
        state.services.forEach((el: any) => {
          if (el.groupIsEnabled && el.isEnabled) {
            model.data.additionalServicesIds.push(el.id);
          }
        });
      } else {
        state.services.forEach((el: any) => {
          if (el.groupIsEnabled && el.isEnabled) {
            model.data.additionalServicesIds.push(el.id);
          }
        });
      }
    };

    const deselectAll = () => {
      model.data.additionalServicesIds = [];
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return "Niepoprawne daty opłaty";
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Grupa już istnieje";
        case 410:
          return "Nie można stworzyć płatnej rejestracji bez podania terminu płatności";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: (v) => !!v || `${root.$t("layout.misc.required")}`,
    };

    watch(
      () => model.data.isRegistrationCodeRequired,
      () => {
        if (model.data.isRegistrationCodeRequired === false) {
          model.data.registrationCode = null;
        }
      }
    );

    const copyAllCodes = (discountCodes: any) => {
      const codes = discountCodes.map((item: string) => item).join(", ");
      navigator.clipboard.writeText(codes);
      root?.$store.commit("snackbar/PUSH_MESSAGE", {
        id: uuid(),
        color: "primary",
        message: root.$tc("event.panel.config.discounts.add.allCodesCopied"),
      });
    };

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      addItem,
      removeItem,
      rules,
      selectAll,
      deselectAll,
      getCodes,
      removeCode,
      copySingleCode,
      addSingleCode,
      copyAllCodes,
    };
  },
});
